.App_title {
  margin: 2rem;
}
.App_wrapper {
  margin: 2rem 0;
  text-align: center;
}
.App_logo {
  max-width: 200px;
  height: auto;
  display: block;
  margin: 2rem 0;
}
